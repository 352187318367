<script>
    export let inversed = false,
        class_name = "";
</script>

{#if inversed}
    <button
            on:click
            class="button-color  max-sm:flex max-sm:flex-wrap font-semibold py-2 px-4 border
        border-accent hover:border-transparent rounded-md {class_name}"
    >
        <slot></slot>
    </button>
{:else}
    <button
            on:click
            class="button-color max-sm:flex max-sm:flex-nowrap
        font-semibold py-2 px-4 border
        hover:border-accent border-transparent rounded-md {class_name}"
    >
        <slot></slot>
    </button>
{/if}

<style>
    .button-color {
        color:var(--color-text-button);
        background-color:var(--color-theme-2)
    }
    .button-color:hover {
        background-color:var(--color-text-button);
        color:var(--color-theme-2)
    }
</style>
